import Swiper from "swiper/bundle";

import "swiper/css/bundle";

const refSwiper = new Swiper(".references-swiper", {
    speed: 300,
    spaceBetween: 20,
    slidesPerView: 2,
    lazyLoading: true,
    loop: true,
    navigation: {
        nextEl: ".references .button-next",
        prevEl: ".references .button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        991: {
            slidesPerView: 2,
        },
    },
});

const viewSwiper = new Swiper(".view-swiper", {
    speed: 300,
    spaceBetween: 20,
    slidesPerView: 2,
    lazyLoading: true,
    loop: true,
    navigation: {
        nextEl: ".views .button-next",
        prevEl: ".views .button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        991: {
            slidesPerView: 2,
        },
    },
});

const servicesSwiper = new Swiper(".services-swiper", {
    speed: 300,
    slidesPerView: 1,
    lazyLoading: true,
    loop: true,
    navigation: {
        nextEl: ".services .button-next",
        prevEl: ".services .button-prev",
    },
});

const thumbsDetailSwiper = new Swiper(".swiper.thumbs-detail-swiper", {
    speed: 300,
    spaceBetween: 15,
    slidesPerView: 3,
    lazyLoading: true,
    freeMode: true,
    watchSlidesProgress: true,
    threshold: 10,
    breakpoints: {
        1200: {
            slidesPerView: 6,
            spaceBetween: 20,
        },
    },
});

const detailSwiper = new Swiper(".swiper.detail-swiper", {
    speed: 300,
    lazyLoading: true,
    loop: true,
    navigation: {
        nextEl: ".detail-cover .button-next",
        prevEl: ".detail-cover .button-prev",
    },
    thumbs: {
        swiper: thumbsDetailSwiper,
    },
});

const similarOffersSwiper = new Swiper(".similar-offers-swiper", {
    speed: 300,
    spaceBetween: 20,
    slidesPerView: 3,
    lazyLoading: true,
    loop: true,
    navigation: {
        nextEl: ".similar-offers .button-next",
        prevEl: ".similar-offers .button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1240: {
            slidesPerView: 3,
        },
    },
});

const team1Swiper = new Swiper(".team-1-swiper", {
    speed: 300,
    spaceBetween: 20,
    slidesPerView: 3,
    lazyLoading: true,
    loop: true,
    navigation: {
        nextEl: ".team-1 .button-next",
        prevEl: ".team-1 .button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 3,
        },
    },
});

//const agentRealtiesSwiper = new Swiper(".agent-realties-swiper", {
//    speed: 300,
//    spaceBetween: 20,
//    slidesPerView: 3,
//    lazyLoading: true,
//    loop: true,
//    navigation: {
//        nextEl: ".agent-realties .button-next",
//        prevEl: ".agent-realties .button-prev",
//    },
//    breakpoints: {
//        320: {
//            slidesPerView: 1,
//        },
//        768: {
//            slidesPerView: 2,
//        },
//        1200: {
//            slidesPerView: 3,
//        },
//    },
//});

//const agentSoldRealtiesSwiper = new Swiper(".agent-sold-realties-swiper", {
//    speed: 300,
//    spaceBetween: 20,
//    slidesPerView: 3,
//    lazyLoading: true,
//    loop: true,
//    navigation: {
//        nextEl: ".agent-sold-realties .button-next",
//        prevEl: ".agent-sold-realties .button-prev",
//    },
//    breakpoints: {
//        320: {
//            slidesPerView: 1,
//        },
//        768: {
//            slidesPerView: 2,
//        },
//        1200: {
//            slidesPerView: 3,
//        },
//    },
//});
