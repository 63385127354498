import Choices from "choices.js";

import "choices.js/public/assets/styles/choices.min.css";

import "./choices.scss";

let choices = document.querySelectorAll(".js-choice");
let choicesMulti = document.querySelectorAll(".js-choice-multi");
let choicesMultiSearch = document.querySelectorAll(".js-choice-multi-search");


choices.forEach((item) => {
    const choiceEl = new Choices(item, {
        searchEnabled: false,
        searchChoices: false,
        shouldSort: false,
        placeholder: false,
        loadingText: "Načítavanie...",
        noResultsText: "Žiadne výsledky",
        noChoicesText: "Žiadne výsledky",
        itemSelectText: "Vybrať",
        position: "bottom",
        callbackOnInit: document
            .querySelector(".filter")
            .classList.add("initialized"),
    });
});

choicesMulti.forEach((item) => {
    const choiceEl = new Choices(item, {
        searchEnabled: false,
        searchChoices: false,
        shouldSort: false,
        loadingText: "Načítavanie...",
        noResultsText: "Žiadne výsledky",
        noChoicesText: "Žiadne výsledky",
        itemSelectText: "Vybrať",
        position: "bottom",
        placeholder: false,
        placeholderValue: 'Všetky',
        renderSelectedChoices: "always",
        removeItems: false,
        removeItemButton: false,
        // callbackOnInit: document
        //     .querySelector(".filter")
        //     .classList.add("initialized"),
    });

    item.addEventListener("choice", (event) => {
        if (event.detail.choice.selected) {
            setTimeout(() => {
                choiceEl.removeActiveItemsByValue(event.detail.choice.value);
                choiceEl._triggerChange(event.detail.choice.value);
            }, 100);
        }
    });
    item.addEventListener("change", () => {
        if (item.querySelector("option")) {
            item.closest(".choice__box")
                .querySelector(".choice__placeholder")
                .classList.add("hide");
        } else {
            item.closest(".choice__box")
                .querySelector(".choice__placeholder")
                .classList.remove("hide");
        }
    });
    if (item.querySelector("option")) {
        item.closest(".choice__box")
            .querySelector(".choice__placeholder")
            .classList.add("hide");
    }
});

choicesMultiSearch.forEach((item) => {
    const choiceEl = new Choices(item, {
        searchEnabled: true,
        searchChoices: true,
        shouldSort: false,
        loadingText: "Načítavanie...",
        noResultsText: "Žiadne výsledky",
        noChoicesText: "Žiadne výsledky",
        itemSelectText: "Vybrať",
        position: "bottom",
        placeholder: false,
        placeholderValue: 'Všetky',
        renderSelectedChoices: "always",
        removeItems: true,
        removeItemButton: true,
        // callbackOnInit: document
        //     .querySelector(".filter")
        //     .classList.add("initialized"),
    });

    item.addEventListener("choice", (event) => {
        if (event.detail.choice.selected) {
            setTimeout(() => {
                choiceEl.removeActiveItemsByValue(event.detail.choice.value);
                choiceEl._triggerChange(event.detail.choice.value);
            }, 100);
        }
    });
    item.addEventListener("change", () => {
        if (item.getAttribute('data-value-to')) {
            let valueToElement = document.getElementById(item.getAttribute('data-value-to'));
        }

        if (item.querySelector("option")) {
            item.closest(".choice__box")
                .querySelector(".choice__placeholder")
                .classList.add("hide");
        } else {
            item.closest(".choice__box")
                .querySelector(".choice__placeholder")
                .classList.remove("hide");
        }
    });
    if (item.querySelector("option")) {
        item.closest(".choice__box")
            .querySelector(".choice__placeholder")
            .classList.add("hide");
    }
});

// let choicesModalLocationElement = document.getElementById("location_modal");
// if (choicesModalLocationElement) {
//
// }
// const choicesModalLocation = new Choices(choicesModalLocation, {
//     searchEnabled: true,
//     searchChoices: true,
//     shouldSort: false,
//     loadingText: "Načítavanie...",
//     noResultsText: "Žiadne výsledky",
//     noChoicesText: "Žiadne výsledky",
//     itemSelectText: "Vybrať",
//     position: "bottom",
//     placeholder: false,
//     placeholderValue: 'Všetky',
//     renderSelectedChoices: "always",
//     removeItems: true,
//     removeItemButton: true,
//     // callbackOnInit: document
//     //     .querySelector(".filter")
//     //     .classList.add("initialized"),
// });
//
// item.addEventListener("choice", (event) => {
//     if (event.detail.choice.selected) {
//         setTimeout(() => {
//             choiceEl.removeActiveItemsByValue(event.detail.choice.value);
//             choiceEl._triggerChange(event.detail.choice.value);
//         }, 100);
//     }
// });
// item.addEventListener("change", () => {
//     if (item.querySelector("option")) {
//         item.closest(".choice__box")
//             .querySelector(".choice__placeholder")
//             .classList.add("hide");
//     } else {
//         item.closest(".choice__box")
//             .querySelector(".choice__placeholder")
//             .classList.remove("hide");
//     }
// });
// if (item.querySelector("option")) {
//     item.closest(".choice__box")
//         .querySelector(".choice__placeholder")
//         .classList.add("hide");
// }
