
window.openOfferModal = function openOfferModal() {
    if (document.getElementById('offer-modal-wrap')) {
        document.getElementById('offer-modal-wrap').classList.add('open');
    }
    if (document.getElementById('overlay')) {
        document.getElementById('overlay').classList.add('open');
    }
};

window.closeOfferModal = function closeOfferModal() {
    if (document.getElementById('offer-modal-wrap')) {
        document.getElementById('offer-modal-wrap').classList.remove('open');
    }
    if (document.getElementById('overlay')) {
        document.getElementById('overlay').classList.remove('open');
    }
};
